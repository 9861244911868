<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        
        <!--  BEGIN CONTENT PART  -->
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3  {{ $t('withrequest.reuestinv')}}
                    
                    div
                       nav.breadcrumb-one(aria-label="breadcrumb")
                          ol.breadcrumb
                              li.breadcrumb-item
                                router-link(to="/pages/home") {{ $t('headers.dashboard')}}
                              li.breadcrumb-item
                                router-link(to="/pages/dashboardCenter") {{ $t('headers.centers')}}
                              li.breadcrumb-item.active(aria-current="page") {{ $t('withrequest.reuestinv')}}
                              
                div.row.mt-5.pb-5
                    request-center-component

                        

</template>

<script>
/* eslint-disable */
import RequestCenterComponent from '@/components/Admin/RequestCenterComponent'
export default {
    name: 'CentersWithRequest',
    components: {
        RequestCenterComponent
    },
    data() {
        return {
        }
    },
    methods: {
    },
    mounted() {

    }
}
</script>
